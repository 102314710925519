import React, { useState } from "react";
import { Body, Content, InputAddressArea } from "./styles";
import apiGd from "services/apiGd";
import Input from "views/components/Input";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import CheckboxInput from "views/components/Checkbox";
import { Map, TileLayer, Marker } from "react-leaflet";
import { LinkStyled } from "components/Cards/styles";  

function EditLocation({ 
  restaurantData, 
  setRestaurantData,  
  inicialLocation,
  setInicialLocation,
  selectedPosition,
  setSelectedPosition,
  locationLimitedCheck,
  changeLocationCheck,
  longitudeHere,
  latitudeHere
 }) {

  const handleChange = (field, value) => {
    setRestaurantData((prevData) => ({
      ...prevData,
      adress: {
        ...prevData.adress,
        [field]: value,
      },
    }));
  };

  const handleChangePosition = (field, value) => {
    setRestaurantData((prevData) => ({
      ...prevData,
        [field]: value,
    }));
  };

  const [validCep, setValidCep] = useState(true);

  async function handleSearchCep(e) {
    if (e.charAt(8) !== "_") {
      const cep = e.replace("-", "").replace(".", "");
      const response = await apiGd.get(`/public/delivery/cep/${cep}`);

      if (!response.data.erro) {
        handleChange("street", response.data.logradouro);
        handleChange("neighborhood", response.data.bairro);
        handleChange("city", response.data.localidade);
        handleChange("state", response.data.uf);
        handleChange("zip_code", response.data.cep);
        setValidCep(true);
      } else {
        handleChange("street", "");
        handleChange("neighborhood", "");
        handleChange("city", "");
        setValidCep(false);
      }
    }
  }

  //geolocaiton
  function searchLocation() {
    if (restaurantData.latitude !== undefined && restaurantData.longitude  !== undefined) {
      setInicialLocation([restaurantData.latitude, restaurantData.longitude]);
      setSelectedPosition([restaurantData.latitude, restaurantData.longitude]);
    } else {
      setInicialLocation([0, 0]);
      setSelectedPosition([0, 0]);
          
      handleChangePosition('latitude',0)
      handleChangePosition('longitude',0)
    }
  }

  function locationHere() {
    setInicialLocation([latitudeHere, longitudeHere]);
    setSelectedPosition([latitudeHere, longitudeHere]);
    
    handleChangePosition('latitude',latitudeHere)
    handleChangePosition('longitude',longitudeHere)
  }

  function handleMapClick(event) {
    setSelectedPosition([event.latlng.lat, event.latlng.lng]);
    handleChangePosition('latitude', event.latlng.lat)
    handleChangePosition('longitude', event.latlng.lng)
  }

  return (
    <Content>
      <span style={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 500 }}>
        Localização
      </span>
      <Body>
        <InputAddressArea>
        <span>CEP</span>
        <Input
          name="CEP"
          value={restaurantData?.adress?.zip_code}
          onChange={(e) => {handleChange('zip_code', e.target.value); handleSearchCep(e.target.value)}}
        />
        </InputAddressArea>
        <InputAddressArea>
          <span>Logradouro</span>
          <Input
            name="Logradouro"
            value={restaurantData?.adress?.street}
            onChange={(e) => handleChange('street', e.target.value)}
          />
        </InputAddressArea>
        <InputAddressArea>
          <span>Número</span>        
          <Input
            name="Número"
            value={restaurantData?.adress?.number}
            onChange={(e) => handleChange('number', e.target.value)}
          />
        </InputAddressArea>
        <InputAddressArea>
          <span>Bairro</span>
          <Input
            name="Bairro"
            value={restaurantData?.adress?.neighborhood}
            onChange={(e) => handleChange('neighborhood', e.target.value)}
          />
        </InputAddressArea>
        <InputAddressArea>
          <span>Cidade</span>
          <Input
            name="Cidade"
            value={restaurantData?.adress?.city}
            onChange={(e) => handleChange('city', e.target.value)}
          />
        </InputAddressArea>
        <InputAddressArea>
          <span>Estado</span>
          <Input
            name="Estado"
            value={restaurantData?.adress?.state}
            onChange={(e) => handleChange('state', e.target.value)}
          />
        </InputAddressArea>    

        {/* <Checkbox
          name="Serviço de geolocalização"
          checked={restaurantData?.geolocation_cb}
          onClick={(e) => handleChange('geolocation_cb', e)}
        /> */}
      </Body>
      <Row>
        <Col sm="12">
          {locationLimitedCheck ? (
            <CheckboxInput
              label="Serviço de Geolocalização"
              checked={locationLimitedCheck}
              name="geolocation_cb"
              onChange={changeLocationCheck}
            />
          ) : (
            <CheckboxInput
              label="Serviço de Geolocalização"
              name="geolocation_cb"
              onChange={changeLocationCheck}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col sm="5">
          <Label>Latitude</Label>
          <FormGroup>
            {locationLimitedCheck ? (
              <Input
                type="float"
                name="latitude"
                id="latitude"
                value={restaurantData.latitude}
                onChange={(e) => handleChangePosition('latitude', e.target.value)}
              />
            ) : (
              <Input
                type="float"
                name="latitude"
                id="latitude"
                value={restaurantData.latitude}
                disabled
                onChange={(e) => handleChangePosition('latitude', e.target.value)}
              />
            )}
          </FormGroup>
          {locationLimitedCheck && (
            <LinkStyled onClick={locationHere}>
              Localização Atual
            </LinkStyled>
          )}
        </Col>
        <Col sm="5">
          <Label>Longitude</Label>
          <FormGroup>
            {locationLimitedCheck ? (
              <Input
                type="float"
                name="longitude"
                id="longitude"
                onChange={(e) => handleChangePosition('longitude', e.target.value)}
                value={restaurantData.longitude}
              />
            ) : (
              <Input
                type="float"
                name="longitude"
                id="longitude"
                disabled
                onChange={(e) => handleChangePosition('longitude', e.target.value)}
                value={restaurantData.longitude}
              />
            )}
          </FormGroup>
        </Col>
        <Col sm="2">
          <div style={{ marginTop: 20 }}>
            <Button onClick={searchLocation} color="info" style={{height: 47}}>
              <i className="nc-icon nc-zoom-split" />
            </Button>
          </div>
        </Col>
      </Row>

      {locationLimitedCheck && (
        <>
          <Label>Geolocalização:</Label>
          <Map
            center={inicialLocation}
            zoom={14}
            style={{ height: 250 }}
            onClick={handleMapClick}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={selectedPosition}></Marker>
          </Map>
        </>
      )}

    </Content>
  );
}

export default EditLocation;
