/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import XLSX from 'xlsx-js-style';
import { format } from 'date-fns';

async function getData(restaurants) {
  const sheet_body = restaurants.map((rest) => {
    const sheet_item = {
      name: rest.name,
      tables: rest.tables_count,
      sessions: rest.sessions_count,
      mrr: rest.mrr ? parseFloat(rest.mrr).toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) : '-',
      created_at: rest.created_at
        ? format(new Date(rest.created_at), 'dd/MM/yyyy')
        : '-',
      hundred_sessions_date: rest.hundred_sessions_date
        ? format(new Date(rest.hundred_sessions_date), 'dd/MM/yyyy')
        : '-',
      two_hundred_sessions_date: rest.two_hundred_sessions_date
      ? format(new Date(rest.two_hundred_sessions_date), 'dd/MM/yyyy')
      : '-',
      last_session_date: rest.last_session_date
        ? format(new Date(rest.last_session_date), 'dd/MM/yyyy')
        : '-',
      responsible: rest.responsible_user?.name || '-',
      deleted_at: rest.deleted_at
        ? format(new Date(rest.deleted_at), 'dd/MM/yyyy')
        : '-',
    };

    return [
      sheet_item.name,
      sheet_item.tables,
      sheet_item.sessions,
      sheet_item.mrr,
      sheet_item.created_at,
      sheet_item.hundred_sessions_date,
      sheet_item.two_hundred_sessions_date,
      sheet_item.last_session_date,
      sheet_item.responsible,
      sheet_item.deleted_at,
    ];
  });

  const sheet_header = [
    'Restaurante',
    'Mesas',
    'Comandas',
    'MRR',
    'Criado',
    '100º Comanda',
    '200º Comanda',
    'Última Comanda',
    'Responsável',
    'Excluído'
  ];

  return {
    data: [sheet_header, ...sheet_body],
  };
}

export async function generateReportSheet(
  restaurants
) {
  const { data } = await getData(
    restaurants
  );

  const columns = 'abcdefghijklmnopqrstuvwxyz'.toLocaleUpperCase().split('');

  const num_cols = data[0].length;
  // const cols_comps = dataisEarning[0].length;

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  // const comp_sheet = XLSX.utils.aoa_to_sheet(dataisEarning);

  // Every column with 15 chars of width
  worksheet['!cols'] = new Array(num_cols);
  worksheet['!cols'].fill({ wch: 15 });
  // comp_sheet['!cols'] = new Array(cols_comps);
  // comp_sheet['!cols'].fill({ wch: 15 });

  const num_lines = data.length;
  // const comp_num_lines = dataisEarning.length;

  for (let j = 0; j < num_cols; j++) {
    for (let i = 1; i <= num_lines; i++) {
      const cell = worksheet[columns[j] + i];

      if (i === 1) {
        cell.s = {
          fill: {
            fgColor: {
              rgb: 'ff6d6d',
            },
            bgColor: {
              rgb: 'ff6d6d',
            },
          },
          border: {
            top: {
              style: 'thin',
              color: {
                rgb: '000000',
              },
            },
            bottom: {
              style: 'thin',
              color: {
                rgb: '000000',
              },
            },
          },
        };
      }

      if (j > 0) {
        cell.s = { ...cell.s, alignment: { horizontal: 'center' } };
      }
    }
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório');

  return XLSX.write(workbook, { type: 'buffer' });
}
