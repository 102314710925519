import React, { useCallback, useState } from "react";
import { Button, Col, Modal, ModalBody, Row, Table } from "reactstrap";
import formatCompleteDate from "services/formatCompleteDate";
import { BiRefresh } from "react-icons/bi";
import apiGd from "services/apiGd";
import { FaEye } from "react-icons/fa";
import { Form } from "@unform/web";
import Input from "views/components/Input";
import Select from "views/components/Form/SelectInput";
import { addHours, format } from "date-fns";
import Textarea from "views/components/Form/Textarea";
import formatDateYear from "services/formatDateYear";

export default function Protocols({ protocols, notify, users, restaurant_id, setProtocols }) {
  const token = localStorage.getItem("@QRExpress:tokenGd");

  const [modalCreate, setModalCreate] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);

  function toggleCreate(){
    setModalCreate(state => !state);
  }

  function toggleInfo(protocol){
    if(!modalInfo){
      setModalInfo(protocol);
    }else{
      setModalInfo(null);
    }
  }

  const handleCreate = useCallback(async (data) => {
    try{
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };

      data.protocol_date = addHours(new Date(data.protocol_date), 12);
      const response = await apiGd.post(`admin/restaurants/protocols/${restaurant_id}`, data, reqConfig);

      setProtocols(state => [response.data, ...state]);
      setModalCreate(state => !state);
    }catch(err){
      console.log(err);
      notify('tr', 'danger', err?.response?.data?.message || 'Erro ao criar protocolo');
    }
  }, [token]);

  const handleUpdate = useCallback(async (data) => {
    try{
      const reqConfig = {
        headers: { Authorization: `Bearer ${token}` },
      };

      data.protocol_date = addHours(new Date(data.protocol_date), 12);
      data.resolution_date = addHours(new Date(data.resolution_date), 12);

      const response = await apiGd.put(`admin/restaurants/protocols/${modalInfo.id}`, data, reqConfig);

      setProtocols(state => [response.data, ...state.filter(st => st.id !== modalInfo.id)]);
      setModalInfo(state => !state);
    }catch(err){
      console.log(err);
      notify('tr', 'danger', err?.response?.data?.message || 'Erro ao criar protocolo');
    }
  }, [token, modalInfo]);

  function getStatus(type){
    switch(type){
      case 'ongoing':
        return 'Em andamento';
      case 'finished':
        return 'Finalizado';
      case 'archived':
        return 'Arquivado';
      default:
        return type;
    }
  }

  const statusOptions = [
    {label: 'Em andamento', value: 'ongoing'},
    {label: 'Finalizado', value: 'finished'}, 
    {label: 'Arquivado', value: 'archived'}, 
  ];

  return (
    <>
      <Button
        onClick={toggleCreate}
        style={{ float: "right",backgroundColor:"#2ec9b7",borderRadius:8 }}
      >
        Novo Protocolo
      </Button>

      <Table>
        <thead>
          <tr>
            <th>Código</th>
            <th>Status</th>
            <th>Título</th>
            <th>Usuário</th>
            <th>Data</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            protocols.map(protocol => (
              <tr>
                <td>{protocol.code}</td>
                <td>{protocol.status && getStatus(protocol.status)}</td>
                <td>{protocol.title}</td>
                <td>{protocol.user?.name}</td>

                <td>
                  {protocol.protocol_date ? formatDateYear(new Date(protocol.protocol_date)) : '-'}
                </td>

                <td>
                  <FaEye
                    style={{ color: "blue", cursor: "pointer", float: 'right' }}
                    size={22}
                    onClick={() => toggleInfo(protocol)}
                  />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>

      <Modal toggle={toggleCreate} isOpen={modalCreate} size="xl">
        <ModalBody>
          <Form onSubmit={handleCreate}>
            <Row> 
              <Col>
                <Input label="Código" name="code" />
              </Col>

              <Col>
                <Select
                  label="Usuário"
                  name="user_id"
                  options={users}
                />
              </Col>

              <Col>
                <Select
                  label="Status"
                  name="status"
                  options={[
                    {label: 'Em andamento', value: 'ongoing'},
                    {label: 'Finalizado', value: 'finished'}, 
                    {label: 'Arquivado', value: 'archived'}, 
                  ]}
                  defaultValue={{label: 'Em andamento', value: 'ongoing'}}
                />
              </Col>

              <Col>
                <Input
                  label="Data"
                  name="protocol_date"
                  type="date"
                  defaultValue={format(new Date, 'yyyy-MM-dd')}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Textarea label="Título" name="title" type="textarea" maxlength={400}/>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  type="submit"
                  style={{ float: "right",backgroundColor:"#2ec9b7",borderRadius:8 }}
                >
                  Novo Protocolo
                </Button>
              </Col>
            </Row>

          </Form>
        </ModalBody>
      </Modal>

      <Modal toggle={toggleInfo} isOpen={modalInfo} size="xl">
        <ModalBody>
          <Form onSubmit={handleUpdate} initialData={modalInfo}>
            <Row> 
              <Col>
                <Input label="Código" name="code" />
              </Col>

              <Col>
                <Select
                  label="Usuário"
                  name="user_id"
                  options={users}
                  defaultValue={users?.find(user => user.value === modalInfo?.user_id)}
                />
              </Col>

              <Col>
                <Select
                  label="Status"
                  name="status"
                  options={statusOptions}
                  defaultValue={statusOptions.find(option => option.value === modalInfo?.status)}
                />
              </Col>

              <Col>
                <Input
                  label="Data"
                  name="protocol_date"
                  type="date"
                  defaultValue={modalInfo?.protocol_date ? format(new Date(modalInfo.protocol_date), 'yyyy-MM-dd') : null}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Textarea label="Título" name="title" type="textarea" maxlength={400}/>
              </Col>
            </Row>

            <div style={{borderTop: '1px solid #6c757da1', marginTop: 15, marginBottom: 15}} />

            <Row>
              <Col>
                <Input
                  label="Data de resolução"
                  name="resolution_date"
                  type="date"
                  defaultValue={modalInfo?.resolution_date ? format(new Date(modalInfo.resolution_date), 'yyyy-MM-dd') : null}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Textarea label="Resolução" name="resolution" type="textarea" maxlength={600}/>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  type="submit"
                  style={{ float: "right",backgroundColor:"#2ec9b7",borderRadius:8 }}
                >
                  Atualizar Protocolo
                </Button>
              </Col>
            </Row>

          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}
