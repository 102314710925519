import React, { Suspense } from "react";
import { Button, Card, CardBody, Col, Table, Row, Input, InputGroup, Label, Spinner } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import apiGd from "services/apiGd";
import { useCallback } from "react";
import { Form } from "@unform/web";
// import Input from "views/components/Input";
import formatCompleteDate from "services/formatCompleteDate";
import Select from "components/Form/SelectInput";
import FileSaver from 'file-saver';

import {AiOutlineSearch } from 'react-icons/ai';
import { format } from "date-fns";
import CardDash from "components/Cards/CardDash";
import { FaQuestionCircle } from "react-icons/fa";
import { generateReportSheet } from "services/generateReportSheet";
import { FaDownload } from 'react-icons/fa';
import  GeneralReportTR from "views/components/GeneralReportTR";

function GeneralReports() {
  const notificationAlert = useRef(null);
  const tokenGd = localStorage.getItem("@QRExpress:tokenGd");
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantsFilter, setRestaurantsFilter] = useState([]);

  const [inicialDateCreate, setInicialDateCreate] = useState(null);
  const [finalDateCreate, setFinalDateCreate] = useState(format(new Date(), 'yyyy-MM-dd'));

  const [inicialDateHundred, setInicialDateHundred] = useState(null);
  const [finalDateHundred, setFinalDateHundred] = useState(format(new Date(), 'yyyy-MM-dd'));

  const [modalCreate, setModalCreate] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(false);

  const [onlyHundred, setOnlyHundred] = useState(false);
  const [showDeleted, setShowDeleted] = useState(true);

  function handleOnlyHundred(){
    setOnlyHundred(!onlyHundred);
  }

  function changeShowDeleted(){
    setShowDeleted(!showDeleted);
  }

  const toggleModalCreate = () => [setModalCreate(!modalCreate)];

  const notify = (place, color, message) => {
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: color,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const [typeFilter, setTypeFilter] = useState(1);

  const getRestaurants = useCallback(async () => {
    setLoading(true);
    try{
      const response = await apiGd.get(`/admin/restaurants`, {
        headers: {
          Authorization: `Bearer: ${tokenGd}`,
        },
        params: {
          count_sessions: true,
          inicialDateCreate,
          finalDateCreate,
          inicialDateHundred,
          finalDateHundred,
          onlyHundred
        }
      });

      const restaurants = response.data.filter(rest => !rest.is_multistore).filter(rest => rest.id !== 1);
  
      setRestaurants(restaurants);
      setRestaurantsFilter(restaurants);

      // setTeste([]);
      // setTypeFilter(typeFilter);
      switch(typeFilter){
        case 1: {
          let thisRestaurants = restaurants;
          setTeste([]);
          setRestaurantsFilter(thisRestaurants);
          break;
        }
        case 2: {
          let thisRestaurants = restaurants.filter(rest => rest.deleted_at);
          setTeste([]);
          setRestaurantsFilter(thisRestaurants);
          break;
        }
        case 3: {
          let thisRestaurants = restaurants.filter(rest => rest.sessions_count < 100);
          setTeste([]);
          setRestaurantsFilter(thisRestaurants);
          break;
        }
        case 4: {
          let thisRestaurants = restaurants.filter(rest => parseFloat(rest.days_since_last_session) >= 3);
          setTeste([]);
          setRestaurantsFilter(thisRestaurants);
          break;
        }
        case 5: {
          let thisRestaurants = restaurants.filter(rest => parseFloat(rest.days_since_last_session) >= 7);
          setTeste([]);
          setRestaurantsFilter(thisRestaurants);
          break;
        }
        case 6: {
          let thisRestaurants = restaurants.filter(rest => rest.suspension_alert === true);
          setTeste([]);
          setRestaurantsFilter(thisRestaurants);
          break;
        }
        case 7: {
          let thisRestaurants = restaurants.filter(rest => rest.deleted_at === null && rest.is_active === false);
          setTeste([]);
          setRestaurantsFilter(thisRestaurants);
          break;
        }
        case 8: {
          let thisRestaurants = restaurants.filter(rest => rest.only_qrcode === false);
          setTeste([]);
          setRestaurantsFilter(thisRestaurants);
          break;
        }
        case 9: {
          let thisRestaurants = restaurants.filter(rest => rest.only_qrcode === true);
          setTeste([]);
          setRestaurantsFilter(thisRestaurants);
          break;
        }
      }
    }catch(err){
      notify("tr", "danger", "Erro ao carregar informações.");
    }
    setLoading(false);
  }, [tokenGd, inicialDateCreate, finalDateCreate, inicialDateHundred, finalDateHundred, onlyHundred, typeFilter]);

  const orderOptions = [
    {label: 'Ordem: Data de Criação', value: 1},
    {label: 'Ordem: Comandas', value: 2},
    {label: 'Ordem: MRR', value: 6},
    {label: 'Ordem: Data da 100º Comanda', value: 3},
    {label: 'Ordem: Data da Última Comanda', value: 5},
    {label: 'Ordem: Mesas', value: 4}
  ];

  const [users, setUsers] = useState([]);
  const [selectUsers, setSelectUsers] = useState([]);

  const getUsers = useCallback(async () => {
    try{
      const res = await apiGd.get("/restaurants/users", {
        headers: {
          Authorization: `Bearer: ${tokenGd}`,
        },
      });
      
      //removendo o usuario Admin/Padrao do nosso dash
      const users = res.data.filter(data => data.id !== 1).map(data => {
        return {
          label: data.name,
          value: data.id
        }
      });

      setUsers(users);
    }catch(err){
      notify("tr", "danger", "Erro ao carregar informações.");
    }
  }, [tokenGd]);

  const handleFilter = useCallback(async (type) => {    
    switch(type){
      case 1: {
        const thisRestaurants = restaurants.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 2: {
        const thisRestaurants = restaurants.sort((a, b) => parseInt(b.sessions_count) - parseInt(a.sessions_count));
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 4: {
        const thisRestaurants = restaurants.sort((a, b) => parseInt(b.tables_count) - parseInt(a.tables_count));
        setRestaurantsFilter([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
    }
  }, [restaurants]);

  const handleDownloadSheet = async () => {
    try {
      const sheet = await generateReportSheet(
        restaurantsFilter
      );

      const uint = new Uint8Array(sheet);
      const blob = new Blob([uint], { type: 'application/excel' });

      FileSaver.saveAs(
        blob,
        `Relatorio Geral (${format(new Date(), 'dd-MM-yyyy')}).xlsx`
      );
    } catch (err) {
      console.log('Error download sheet > ', err);
    }
  };

  const [type, setType] = useState(1);

  const [test, setTeste] = useState(null);

  useEffect(() => {
    getRestaurants();
    getUsers();
  }, []);

  useEffect(() => {
    switch(type){
      case 1: {
        let thisRestaurants = restaurantsFilter.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 2: {
        let thisRestaurants = restaurantsFilter.sort((a, b) => parseInt(b.sessions_count) - parseInt(a.sessions_count));
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 3: {
        let thisRestaurants = restaurantsFilter.sort((a, b) => new Date(b.hundred_sessions_date) - new Date(a.hundred_sessions_date));
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 4: {
        let thisRestaurants = restaurantsFilter.sort((a, b) => parseInt(b.tables_count) - parseInt(a.tables_count));
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 5: {
        let thisRestaurants = restaurantsFilter.sort((a, b) => new Date(b.last_session_date) - new Date(a.last_session_date));
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 6: {
        let thisRestaurants = restaurantsFilter.sort((a, b) => b.mrr - a.mrr);
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
    }
  }, [type]);

  const filterOptions = [
    {label: 'Todos os Restaurantes', value: 1},
    {label: 'Restaurantes Excluídos', value: 2},
    {label: 'Menos de 100 Comandas', value: 3},
    {label: 'Inativo a 3 dias ', value: 4},
    {label: 'Inativo a 7 dias ', value: 5},
    {label: 'Alerta de Suspensão ', value: 6},
    {label: 'Inadimplente', value: 7},
    // {label: 'Garçom Digital', value: 8},
    // {label: 'Apenas Visualização', value: 9},
  ];

  useEffect(() => {
    switch(typeFilter){
      case 1: {
        let thisRestaurants = restaurants;
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 2: {
        let thisRestaurants = restaurants.filter(rest => rest.deleted_at);
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 3: {
        let thisRestaurants = restaurants.filter(rest => rest.sessions_count < 100);
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 4: {
        let thisRestaurants = restaurants.filter(rest => parseFloat(rest.days_since_last_session) >= 3);
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 5: {
        let thisRestaurants = restaurants.filter(rest => parseFloat(rest.days_since_last_session) >= 7);
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 6: {
        let thisRestaurants = restaurants.filter(rest => rest.suspension_alert === true);
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 7: {
        let thisRestaurants = restaurants.filter(rest => rest.deleted_at === null && rest.is_active === false);
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 8: {
        let thisRestaurants = restaurants.filter(rest => rest.only_qrcode === false);
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
      case 9: {
        let thisRestaurants = restaurants.filter(rest => rest.only_qrcode === true);
        setTeste([]);
        setRestaurantsFilter(thisRestaurants);
        break;
      }
    }
  }, [typeFilter]);

  function removeAcentos(str){
    let txt = str || '';
    txt = txt.toLowerCase();
    txt = txt.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return txt;
  }

  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="8">
            <h3>Relatório Geral</h3>
          </Col>
          <Col md="4">
            
          </Col>
        </Row>
        <Form onSubmit={() => getRestaurants()}>
          <Row style={{margin: 15}}>
            <Col md="2">
              <p>Inicial de Criação</p>
              <Input name="datetime" type="date" label="Data" onChange={e => setInicialDateCreate(e.target.value)}/>
            </Col>
            <Col md="2">
              <p>Final</p>
              <Input name="datetime" type="date" label="daDte" defaultValue={format(new Date(), 'yyyy-MM-dd')} onChange={e => setFinalDateCreate(e.target.value)}/>
            </Col>
            <Col style={{borderLeft: '1px solid #DDDDDD', paddingRight: 15}} md="2">
              <p>Inicial 100 Com.</p>
              <Input name="datetime" type="date" label="Data" onChange={e => setInicialDateHundred(e.target.value)} disabled={!onlyHundred}/>
              <InputGroup style={{marginLeft: 25, marginTop: 10}}>
                <Input type="checkbox" onChange={e => handleOnlyHundred()}/>
                <Label>Somente 100 com.</Label>
              </InputGroup>
            </Col>
            <Col md="2">
              <p>Final</p>
              <Input name="datetime" type="date" label="Data" defaultValue={format(new Date(), 'yyyy-MM-dd')} onChange={e => setFinalDateHundred(e.target.value)} disabled={!onlyHundred}/>
            </Col>
            <Col md="4">
              <Button name="search_button" style={{float: 'right'}} color="success" type="submit">
                <AiOutlineSearch size={17}/>
              </Button>
            </Col>
          </Row>
        </Form>

      <Row>
        <Col md="4">
          <CardDash
            title="Restaurantes Ativos"
            total={`${restaurants?.filter(rest => !rest.name.startsWith('deleted')).filter(rest => selectUsers.length > 0 ? (selectUsers.includes(rest?.responsible_user?.id)) : true).length}`
            }
            icon="nc-icon nc-shop text-info"
          />
        </Col>
        <Col md="4">
          <CardDash
            title="100 comandas"
            total={restaurantsFilter
              .filter(rest => rest.hundred_sessions_date)
              .filter(rest => !showDeleted ? rest.deleted_at === null : true)
              .filter(rest => selectUsers.length > 0 ? (selectUsers.includes(rest?.responsible_user?.id)) : true)
              .length
            }
            icon="nc-icon nc-check-2 text-success"
          />
        </Col>
        <Col md="4">
          <CardDash
            title="Restaurantes Excluídos"
            total={restaurantsFilter?.filter(rest => rest.name.startsWith('deleted'))
              .filter(rest => !showDeleted ? rest.deleted_at === null : true)
              .filter(rest => selectUsers.length > 0 ? (selectUsers.includes(rest?.responsible_user?.id)) : true)
              .length
            }
            icon="nc-icon nc-simple-remove text-danger"
          />
        </Col>
      </Row>

      <Row>
        <Col md="4">
          <CardDash
            title="Média / 100 Comandas"
            total={`${((restaurantsFilter?.filter(rest => rest.hundred_sessions_date)
              .filter(rest => !showDeleted ? rest.deleted_at === null : true)
              .filter(rest => selectUsers.length > 0 ? (selectUsers.includes(rest?.responsible_user?.id)) : true)
              .reduce((acc, rest) => acc + parseInt(rest.difference_activate_days), 0))/(restaurantsFilter?.filter(rest => rest.hundred_sessions_date).length || 1))?.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })} dias`}
            icon="nc-icon nc-time-alarm text-warning"
          />
        </Col>
        <Col md="4">
          <CardDash
            title="Média / Permanência"
            total={`${((restaurantsFilter?.filter(rest => rest.deleted_at)
              .filter(rest => !showDeleted ? rest.deleted_at === null : true)
              .filter(rest => selectUsers.length > 0 ? (selectUsers.includes(rest?.responsible_user?.id)) : true)
              .reduce((acc, rest) => acc + parseInt(rest.days_since_deleted), 0))/(restaurantsFilter?.filter(rest => rest.deleted_at).length || 1))?.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })} dias`}
            icon="nc-icon nc-calendar-60 text-success"
          />
        </Col>
        <Col md="4">
          <CardDash
            title="Inadimplentes"
            total={restaurantsFilter?.filter(rest => rest.deleted_at === null && rest.is_active === false)
              .filter(rest => !showDeleted ? rest.deleted_at === null : true)
              .filter(rest => selectUsers.length > 0 ? (selectUsers.includes(rest?.responsible_user?.id)) : true)
              .length
            }
            icon="nc-icon nc-user-run text-danger"
          />
        </Col>
      </Row>

      <Row>
        <Col md="5">
          <Form>
            <Input 
              name="input" 
              style={{height: 40, marginTop: 20}} 
              onChange={e => {
                setSearch(removeAcentos(e.target.value) || '');
              }}
              placeholder="Pesquise..."
            />
          </Form>
        </Col>
        <Col md="3">
          <Form>
            <div style={{ position: "sticky", zIndex: 3}}>
              <Select 
                name="selecty"
                options={orderOptions}
                defaultValue={orderOptions[0]}
                onChange={e => setType(e.value)}
              />
              </div>
          </Form>
        </Col>
        <Col md="3">
          <Form>
            <div style={{ position: "sticky", zIndex: 3}}>
              <Select 
                name="select-filter"
                options={filterOptions}
                defaultValue={filterOptions[0]}
                onChange={e => setTypeFilter(e.value)}
              />
              </div>
          </Form>
        </Col>
        <Col md="1">
          <Button name="download_button" style={{float: 'right', marginTop: 20}} color="default" onClick={() => handleDownloadSheet()}>
            <FaDownload size={15}/>
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md="5">
          <InputGroup style={{marginLeft: 25, marginTop: 30}}>
            <Input type="checkbox" onChange={e => changeShowDeleted()} defaultChecked={showDeleted}/>
            <Label>Incluir Restaurantes Excluídos</Label>
          </InputGroup>
        </Col>
        <Col md="6">
          <Form>
            <div style={{ position: "sticky", zIndex: 2 }}>
              <Select 
                name="select_users"
                isMulti
                options={users}
                placeholder="Filtrar responsáveis"
                onChange={e => setSelectUsers((e && e.length > 0) ? e.map(us => us.value) : [])}
              />
              </div>
          </Form>
        </Col>
      </Row>

      <Card style={{ overflowY: "scroll", maxHeight: "600px", marginTop: 15 }}>
      {/* <Card> */}
        <CardBody>
         { loading ? 
            <div style={{color: 'red', padding: 15, textAlign: 'center'}}>
              <Spinner /> 
            </div> :
          <Table>
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#ffffff",
                zIndex: 1,
              }}
            >
              <tr>
                <th style={{fontSize: 12}}>Restaurante</th>
                <th style={{fontSize: 12}} className="text-center">Responsável</th>
                <th style={{fontSize: 12}} className="text-center">Mesas</th>
                <th style={{fontSize: 12}} className="text-center">Comandas</th>
                <th style={{fontSize: 12}} className="text-center">MRR</th>
                <th style={{fontSize: 12}} className="text-center">Criado</th>
                <th style={{fontSize: 12}} className="text-center">100º Comanda*</th>
                <th style={{fontSize: 12}} className="text-center">200º Comanda*</th>
                {/* <th style={{fontSize: 12}} className="text-center">Dias Até 100º</th> */}
                <th style={{fontSize: 12}} className="text-center">Última Comanda**</th>
                {(typeFilter === 6 || typeFilter === 7) && <th style={{fontSize: 12}} className="text-center">Boleto Venc.</th>}
                <th style={{fontSize: 12}} className="text-center">Excluído***</th>
                <th style={{fontSize: 12}} className="text-center">Data suspensão</th>
                <th style={{fontSize: 12}} className="text-right">Liberação temporária</th>
              </tr>
            </thead>
            <tbody>  
              <Suspense fallback={<div>Loading...</div>}>
                {(search ? 
                  restaurantsFilter
                  .filter(rest => selectUsers.length > 0 ? (selectUsers.includes(rest?.responsible_user?.id)) : true)
                  .filter(rest => !showDeleted ? rest.deleted_at === null : true)
                  .filter(rest => removeAcentos(rest.fantasy_name).includes(search) || removeAcentos(rest.name).includes(search)) 
                  : restaurantsFilter
                  .filter(rest => selectUsers.length > 0 ? (selectUsers.includes(rest?.responsible_user?.id)) : true)
                  .filter(rest => !showDeleted ? rest.deleted_at === null : true))
                  .map((restaurant) => (
                    <GeneralReportTR restaurant={restaurant} typeFilter={typeFilter}/>
                ))}
              </Suspense>
            </tbody>
          </Table>
          }
        </CardBody>
      </Card>

      <Row>
        <Col>
          <p><strong>*</strong> Os dias são a quantidade de dias que o restaurante demorou para atingir 100 comandas desde seu cadastro.</p>
          <p><strong>**</strong> Os dias são a quantidade de dias desde a última comanda do restaurante.</p>
          <p><strong>***</strong> Os dias são a quantidade de dias que o restaurante ficou no nosso sistema, entre o cadastro e a exclusão.</p>
        </Col>
      </Row>
      </div>
    </>
  );
}

export default GeneralReports;
