import { format, parseISO } from "date-fns";
import pt from "date-fns/locale/pt";

export default function formatDateYear(date) {
  if (!(date instanceof Date)) {
    return format(parseISO(date), "dd/MM/yy", {
      locale: pt,
    });
  }

  return format(date,  "dd/MM/yy", {
    locale: pt,
  });
}